import i18n from "@/i18n";

const routes = {
  path: "/brand",
  name: "Brand",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/brand/Main"),
  children: [
    {
      path: "adminbrandlist",
      alias: "",
      name: "AdminBrandList",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/brand/AdminBrandList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.brand.brand_list"),
        menu_num: 10,
        sub_menu_num: 101,
        step: [
          i18n.t("menu.brand.brand"),
          i18n.t("page.brand.brand_list"),
        ],
      },
    },
    {
      path: "adminbrandwrite/:brandno?",
      name: "AdminBrandWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/brand/AdminBrandWrite"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.brand.brand_write"),
        menu_num: 10,
        sub_menu_num: 101,
        step: [
          i18n.t("menu.brand.brand"),
          i18n.t("page.brand.brand_write"),
        ],
      },
    },
  ],
};

export default routes;
