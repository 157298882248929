import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "ready", // ready, loading, error, success,
    searchData: {},
    notification: [],
    admin: { brandList: [], brandno: 0 },
  },
  mutations: {
    authRequest(state) {
      state.status = "loading";
    },
    authSuccess(state) {
      state.status = "success";
      state.searchData = {};
    },
    authError(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "ready";
      state.searchData = {};
      state.notification = [];
      state.admin.brandList = [];
    },
    setSearchData(state, payload) {
      if (Object.keys(payload).length === 0) state.searchData = {};
      state.searchData = { ...state.searchData, ...payload };
    },
    setNotification(state, payload) {
      state.notification = payload;
    },
    setBrandlist(state, payload) {
      state.admin.brandList = payload;
    },
    setBrandno(state, payload) {
      state.admin.brandno = payload;
    },
  },
  actions: {
    login({ commit }, { token, user, pk, isAdmin }) {
      commit("authRequest");
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("user", user);
      sessionStorage.setItem("pk", pk);
      sessionStorage.setItem("isAdmin", isAdmin);
      commit("authSuccess");
    },
    logout({ commit }) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("pk");
      sessionStorage.removeItem("isAdmin");
      commit("logout");
    },
    check_login({ commit }) {
      commit("authSuccess");
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
