import i18n from "@/i18n";

const routes = {
  path: "/account",
  name: "Account",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/account/Main"),
  children: [
    {
      path: "brandpwdcheck",
      name: "BrandPwdCheck",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/account/BrandPwdCheck"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.membership.confirm_password"),
        menu_num: 9,
        sub_menu_num: 91,
        step: [
          i18n.t("menu.membership.my_account"),
          i18n.t("page.membership.confirm_password"),
          /* i18n.t("page.membership.cancel_membership"), */
        ],        
      },
    },
    {
      path: "brandwrite",
      name: "BrandWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/account/BrandWrite"
        ),
      props: true,
      password_yn: "",
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.membership.my_profile"),
        menu_num: 9,
        sub_menu_num: 92,
        step: [
          i18n.t("menu.membership.my_account"),
          i18n.t("page.membership.my_profile"),
          /* i18n.t("page.membership.cancel_membership"), */
        ], 
      },
    },
  ],
};

export default routes;
