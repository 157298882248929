import i18n from "@/i18n";

const routes = {
  path: "/ad",
  name: "Ad",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/ad/Main"),
  children: [
    {
      path: "adlist/1/:goodstype?",
      alias: "",
      name: "AdList1",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/ad/AdList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.ad.ad_movie"),
        menu_num: 2,
        sub_menu_num: 211,
        step: [
          i18n.t("menu.ad.ad"),
          i18n.t("menu.ad.ad_movie"),
        ],  
        adtype: 1,
      },
    },

    {
      path: "adlist/2/:goodstype?",
      alias: "",
      name: "AdList2",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/ad/AdList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.ad.ad_title"),
        menu_num: 2,
        sub_menu_num: 221,
        step: [
          i18n.t("menu.ad.ad"),
          i18n.t("menu.ad.ad_title"),
        ],
        adtype: 2,
      },
    },

    {
      path: "adlist/3/:goodstype?",
      alias: "",
      name: "AdList3",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/ad/AdList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.ad.ad_goods"),
        menu_num: 2,
        sub_menu_num: 231,
        step: [
          i18n.t("menu.ad.ad"),
          i18n.t("menu.ad.ad_goods"),
        ],
        adtype: 3,
      },
    },
    {
      path: "adwrite/:adno?",
      name: "AdWrite",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/ad/AdWrite"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.ad.ad_write"),
        menu_num: 2,
        sub_menu_num: 24,
        step: [
          i18n.t("menu.ad.ad"),
          i18n.t("menu.ad.ad_write"),
        ],
      },
    },
  ],
};

export default routes;
