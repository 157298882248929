import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import _ from "lodash";
import auth from "@/api/auth";

import accountPaths from "./path.account";
import adPaths from "./path.ad";
import customerPaths from "./path.customer";
import fittingPaths from "./path.fitting";
import goodsPaths from "./path.goods";
import memberPaths from "./path.member";
import salePaths from "./path.sale";
import shopPaths from "./path.shop";
import statsPaths from "./path.stats";
import brandPaths from "./path.brand";
import i18n from "@/i18n";

Vue.use(VueRouter);

let allowNullName = ["Login", "Logout"];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import(/* webpackChunkName: "main" */ "@/views/Main"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "Dashboard",
        m_subtitle: "Dashboard",
        step: ["Dashboard"],
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
      meta: {
        layout: "NoneLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.login.login"),
        m_subtitle: i18n.t("page.login.login"),
        step: [i18n.t("page.login.login")],
      },
    },
    {
      path: "/logout",
      name: "Logout",
      beforeEnter: (to, from, next) => {
        store.dispatch("logout");
        next("/login");
      },
    },

    {
      path: "*",
      component: () => import(/* webpackChunkName: "404" */ "@/views/404"),
      meta: {
        layout: "NoneLayout",
      },
    },
    ,
    {
      path: "/terms_of_use",
      name: "Terms",
      component: () => import(/* webpackChunkName: "signup" */ "@/views/Terms"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.main.terms_of_use"),
        m_subtitle: i18n.t("page.main.terms_of_use"),
        step: [i18n.t("page.main.terms_of_use")],
      },
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: () =>
        import(/* webpackChunkName: "signup" */ "@/views/Privacy"),
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.main.privacy_policy"),
        m_subtitle: i18n.t("page.main.privacy_policy"),
        step: [i18n.t("page.main.privacy_policy")],
      },
    },
  ],
});

router.addRoute(accountPaths);
router.addRoute(adPaths);
router.addRoute(customerPaths);
router.addRoute(fittingPaths);
router.addRoute(goodsPaths);
router.addRoute(memberPaths);
router.addRoute(salePaths);
router.addRoute(shopPaths);
router.addRoute(statsPaths);
router.addRoute(brandPaths);

/*
router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/notFound')
  } else {
    if (_.includes(allowNullName, to.name)) {
      next()
    } else if (store.state.user && to.name === 'Splash') {
      next({
        path: '/dashboard',
        replace: true
      })
    } else if (!store.state.user && to.name === 'Splash') {
      next({
        path: '/dashboard',
        replace: true
      })
    } else if (store.state.user === null) {
      await auth.refreshToken((flag) => {
        if (flag) {
          next()
        } else {
          next({
            path: '/dashboard',
            replace: true
          })
        }
      })
    } else {
      next()
    }`
  }
})
*/
router.beforeEach(async (to, from, next) => {
  console.log("to", to);
  console.log("from", from);
  if (from.meta && to.meta && to.meta.sub_menu_num !== from.meta.sub_menu_num) {
    //console.log(1111);

    console.log("to", to);
    console.log("from", from);
    if (
      from.meta &&
      to.meta &&
      from.name &&
      to.meta.menu_num !== from.meta.menu_num
    ) {
      console.log("beforeEachto", to);
      console.log("beforeEachfrom", from);
      store.commit("setSearchData", {});
    }

    // if (from.matched[0] && to.matched[0]) {
    //   if (
    //     (from.matched[0].path !== "/member" ||
    //       to.matched[0].path !== "/member") &&
    //     (from.matched[0].path !== "/booking" ||
    //       to.matched[0].path !== "/booking")
    //   ) {
    //     store.commit("setSearchData", {});
    //   }
    // }
  }

  if (!to.matched.length) {
    next("/notFound");
  } else {
    if (_.includes(allowNullName, to.name)) {
      if (
        to.name === "Login" &&
        (localStorage.getItem("token") || sessionStorage.getItem("token"))
      ) {
        alert(this.$i18n.t("alert.login.already_logged_in"));
        next({
          path: "/",
          replace: true,
        });
      }
      next();
    } else if (
      !localStorage.getItem("token") &&
      !sessionStorage.getItem("token")
    ) {
      store.dispatch("logout");
      next({
        path: "/login",
        replace: true,
      });
    } else {
      next();
    }
  }
});
export default router;
