import i18n from "@/i18n";

export const myMixin = {
  data() {
    return {
      engMonth: {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December"
      }
    };
  },
  methods: {
    tournament_status(status) {
      if (status === "1") {
        return i18n.t("text.common.ready");
      } else if (status === "2") {
        return i18n.t("text.common.playing");
      } else if (status === "3") {
        return i18n.t("text.common.completed");
      } else if (status === "4") {
        return i18n.t("text.common.cancel");
      }
    },
    change_status(status) {
      if (status === "0") {
        return i18n.t("text.common.total");
      } else if (status === "1") {
        return i18n.t("text.common.ready");
      } else if (status === "2") {
        return i18n.t("text.common.playing");
      } else if (status === "3") {
        return i18n.t("text.common.completed");
      } else if (status === "4") {
        return i18n.t("text.common.cancel");
      }
    },
    event_type(event) {
      if (event == 0) {
        return i18n.t("text.common.total");
      } else if (event == 1) {
        return i18n.t("text.common.holeinone");
      } else if (event == 2) {
        return i18n.t("text.common.albatross");
      } else if (event == 3) {
        return i18n.t("text.common.eagle_p5");
      } else if (event == 4) {
        return i18n.t("text.common.birdie_p5");
      } else if (event == 5) {
        return i18n.t("text.common.par_p5");
      } else if (event == 6) {
        return i18n.t("text.common.eagle_p4");
      } else if (event == 7) {
        return i18n.t("text.common.birdie_p4");
      } else if (event == 8) {
        return i18n.t("text.common.par_p4");
      } else if (event == 9) {
        return i18n.t("text.common.longest");
      } else if (event == 10) {
        return i18n.t("text.common.nearpin");
      } else if (event == 11) {
        return i18n.t("text.common.longest_rank");
      } else if (event == 12) {
        return i18n.t("text.common.nearpin_rank");
      }
    },
    changeDate(date) {
      let dateArray = date.split("-");
      let engDate =
        this.engMonth[dateArray[1]] + " " + dateArray[2] + ", " + dateArray[0];
      return engDate;
    },
    getDate(date) {
      if (date) {
        var date = new Date(date);
        var now_utc = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds()
        );

        return new Date(now_utc).toISOString().split("T")[0];
      } else {
        return "";
      }
    },
    changeGender(gender) {
      if (gender === "M") {
        return i18n.t("text.common.male");
      } else if (gender === "F") {
        return i18n.t("text.common.female");
      } else {
        return "";
      }
    },
    play_type(type) {
      if (type === "1") {
        return i18n.t("text.common.stroke");
      } else if (type === "2") {
        return i18n.t("text.common.match");
      } else if (type === "3") {
        return i18n.t("text.common.skins");
      } else if (type === "4") {
        return i18n.t("text.common.approach");
      } else if (type === "5") {
        return i18n.t("text.common.driveway");
      } else if (type === "6") {
        return i18n.t("text.common.par3_play");
      } else if (type === "7") {
        return i18n.t("text.common.longest_contest");
      } else if (type === "8") {
        return i18n.t("text.common.putting_contest");
      } else if (type === "9") {
        return i18n.t("text.common.nearpin_contest");
      }
    },
    tournament_level(level) {
      if (level === "1") {
        return i18n.t("text.common.pro");
      } else if (level === "2") {
        return i18n.t("text.common.semi_pro");
      } else if (level === "3") {
        return i18n.t("text.common.advanced");
      } else if (level === "4") {
        return i18n.t("text.common.normal");
      } else if (level === "5") {
        return i18n.t("text.common.beginner");
      } else {
        return "-";
      }
    },
    club_type(event) {
      if (event == 0) {
        return i18n.t("filter.common.total");
      } else if (event == 1) {
        return i18n.t("filter.common.driver");
      } else if (event == 2) {
        return i18n.t("filter.common.wood");
      } else if (event == 3) {
        return i18n.t("filter.common.utility");
      } else if (event == 4) {
        return i18n.t("filter.common.iron");
      } else if (event == 5) {
        return i18n.t("filter.common.wedge");
      } else if (event == 6) {
        return i18n.t("filter.common.putter");
      // } else if (event == 7) {
      //   return "기타";     
      }
    },
    goods_type(event) {
      if (event == 0) {
        return i18n.t("filter.common.total");
      } else if (event == 1) {
        return i18n.t("filter.common.driver");
      } else if (event == 2) {
        return i18n.t("filter.common.wood");
      } else if (event == 3) {
        return i18n.t("filter.common.utility");
      } else if (event == 4) {
        return i18n.t("filter.common.iron");
      } else if (event == 5) {
        return i18n.t("filter.common.wedge");
      } else if (event == 6) {
        return i18n.t("filter.common.putter");
      } else if (event == 7) {
        return i18n.t("filter.common.shaft");
      } else if (event == 8) {
        return i18n.t("filter.common.grip");
      }
    },
  }
};
