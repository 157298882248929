<template>
  <div>
    <div v-if="roomno" @click="getRoomInfo(shopno, roomno)">
      {{ roomno }} {{ $t("title.location.room") }}
    </div>
    <b-modal
      id="modal-info-room"
      ref="modal-info-room"
      size="xl"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">{{ $t("page.location.room_information") }}</h5>
        <b-button variant="close" @click="close()"></b-button>
      </template>
      <template>
        <h6 class="sub-title">
          <p class="mb-2 brand-name">
            <small>{{ brandname }}</small>
          </p>
          <div class="d-flex align-items-center">
            <div class="shop-name fw-bold">{{ shoptitle }}</div>
            <div class="vr mx-2"></div>
            <div>{{ roomno }} {{ $t("title.location.room") }}</div>
          </div>
        </h6>
        <table class="table board table-sm" v-if="data_yn">
          <thead>
            <tr>
              <th class="col-1 d-lg-table-cell text-center text-nowrap">
                {{ $t("title.common.club_type") }}
              </th>
              <th class="col-auto d-lg-table-cell text-center">
                {{ $t("title.location.detail") }}
              </th>
              <th class="th-arrow">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item1, index1) in roomgoodslist">
              <tr :key="index1 + 'r'">
                <td
                  class="col-1 d-lg-table-cell border-end goods-kind text-center text-nowrap"
                >
                  {{ club_type(item1.roomgoodstype) }}
                </td>
                <td class="col d-lg-table-cell p-0">
                  <table>
                    <template
                      v-for="(item2, index2) in item1.roomgoodsinfodetail"
                    >
                      <tr v-if="index2 == 0" :key="'a' + index2">
                        <td class="col-1 d-none d-lg-table-cell">
                          <img
                            :src="goods_imgview(item1.roomgoodsimage)"
                            class="img-thumbnail"
                          />
                        </td>
                        <td class="col d-lg-table-cell text-start">
                          <span class="goods-name"
                            >{{ item1.roomgoodsname }}
                            {{ item2.clubtypename }}</span
                          >
                          ({{ item2.clubloft }}&deg;)
                        </td>
                        <td class="col-1 w-1 d-none d-lg-table-cell">
                          <select
                            :placeholder="$t('title.common.flex')"
                            v-model="roomgoodsbalancecode[index1]"
                            v-if="admin_login"
                            class="w-auto"
                          >
                            <option value="">
                              {{ $t("title.common.flex") }}
                            </option>
                            <option value="1">R</option>
                            <option value="2">SR</option>
                            <option value="3">S</option>
                            <option value="4">L</option>
                          </select>
                          <div v-else>{{ item2.roomgoodsbalance }}</div>
                        </td>
                        <td
                          class="col-1 d-none d-lg-table-cell text-nowrap text-date"
                        >
                          {{ item2.roomgoodsdate }}
                        </td>
                        <td
                          class="col-1 d-lg-table-cell"
                          v-if="item1.roomgoodscnt > 1"
                        >
                          <b-button
                            size="xs"
                            variant="bundle bg-primary m-auto"
                            :aria-labe="$t('button.common.more')"
                            :class="{ show: view_hide_room_club === index1 }"
                            @click="toggle_club_tr(index1)"
                          ></b-button>
                        </td>
                        <td
                          class="col d-none d-lg-table-cell w-1 text-nowrap"
                          v-if="admin_login"
                        >
                          <b-button
                            size="xs"
                            variant="secondary"
                            :aria-labe="$t('button.common.edit')"
                            @click="setRoomGoodsEdit(index1)"
                          >
                            {{ $t("button.common.edit") }}
                          </b-button>
                          <b-button
                            size="xs"
                            variant="outline-secondary"
                            :aria-labe="$t('button.common.delete')"
                            @click="setRoomGoodsDrop(index1)"
                          >
                            {{ $t("button.common.delete") }}
                          </b-button>
                        </td>
                      </tr>
                      <tr
                        :key="'a' + index2"
                        v-if="view_hide_room_club === index1 && index2 > 0"
                      >
                        <td class="col-auto d-none d-lg-table-cell border-top">
                          <img
                            :src="goods_imgview(item1.roomgoodsimage)"
                            class="img-thumbnail"
                          />
                        </td>
                        <td
                          class="col-auto d-lg-table-cell text-start border-top"
                        >
                          <span class="goods-name"
                            >{{ item1.roomgoodsname }}
                            {{ item2.clubtypename }}</span
                          >
                          ({{ item2.clubloft }}&deg;)
                        </td>
                        <td class="col-auto d-none d-lg-table-cell border-top">
                          <!-- {{ item2.roomgoodsbalance }} -->

                          <select
                            :placeholder="$t('title.common.flex')"
                            v-model="roomgoodsbalancecode[index1]"
                            v-if="admin_login"
                          >
                            <option value="">
                              {{ $t("title.common.flex") }}
                            </option>
                            <option value="1">R</option>
                            <option value="2">SR</option>
                            <option value="3">S</option>
                            <option value="4">L</option>
                          </select>
                        </td>
                        <td
                          class="col-auto d-none d-lg-table-cell text-date border-top"
                        >
                          {{ item2.roomgoodsdate }}
                        </td>
                        <td
                          class="col-auto d-none- d-lg-table-cell border-top"
                        ></td>
                        <td
                          class="col-auto d-none d-lg-table-cell td-arrow border-top"
                        >
                          &nbsp;
                        </td>
                      </tr>
                    </template>
                  </table>
                </td>

                <td class="td-arrow">
                  <img
                    src="/img/arrow_none_collapsed.png"
                    :class="{ 'btn-toggle-arrow': view_hide_room === index1 }"
                    @click="toggle_room_tr(index1)"
                  />
                </td>
              </tr>
              <tr
                class="col-auto d-lg-none"
                :class="{ show: view_hide_room === index1 }"
                :key="index1"
                v-if="view_hide_room === index1"
              >
                <td></td>
                <td colspan="2">
                  <template
                    v-for="(item2, index2) in item1.roomgoodsinfodetail"
                  >
                    <div
                      class="toggle-content"
                      v-if="index2 == 0"
                      :key="index2"
                    >
                      <div class="row">
                        <div class="col-4 align-self-center">
                          {{ $t("title.common.basic_image") }}
                        </div>
                        <div class="col-8">
                          <img
                            :src="goods_imgview(item1.roomgoodsimage)"
                            class="img-thumbnail"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.flex") }}
                        </div>
                        <div class="col-8">{{ item2.roomgoodsbalance }}</div>
                      </div>
                      <div class="row">
                        <div class="col-4">{{ $t("title.common.date") }}</div>
                        <div class="col-8 text-date">
                          {{ item2.roomgoodsdate }}
                        </div>
                      </div>
                      <div class="row" v-if="admin_login">
                        <div class="col-4 align-self-center">
                          {{ $t("title.common.management") }}
                        </div>
                        <div class="col-8">
                          <b-button
                            size="xs"
                            variant="secondary"
                            :aria-labe="$t('button.common.edit')"
                            @click="setRoomGoodsEdit(index1)"
                          >
                            {{ $t("button.common.edit'") }}
                          </b-button>
                          <b-button
                            size="xs"
                            variant="outline-secondary"
                            :aria-labe="$t('button.common.delete')"
                            @click="setRoomGoodsDrop(index1)"
                          >
                            {{ $t("button.common.delete'") }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <!-- <div v-for="(item1, index1) in roomgoodslist" :key="index1">
                {{ item1.roomno }} : {{ item1.roomgoodstype }} : {{ item1.roomgoodsno }}
                <div v-for="(item2, index2) in item1.roomgoodsinfodetail" :key="index2">
                  {{ item1.roomgoodsimage }}  {{ item1.roomgoodsname }} {{ item2.clubtypename }} {{ item2.clubloft }}
                </div>
              </div> -->
      </template>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-labe="$t('button.common.close')"
          @click="
            $bvModal.hide('modal-info-room');
            $emit('getshoplist');
          "
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ax from "@/api/shop";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "RoomInfo",
  props: {
    shopno: String,
    shoptitle: String,
    roomno: String,
    brandno: String,
    brandname: String,
    getshoplist: Function,
  },
  data() {
    return {
      // playerno: "",
      data_yn: true,
      view_hide_room_club: null,
      view_hide_room: null,
      // roomlistcnt: 0,
      // roomlist:[],
      // roomgoodslistcnt: 0,
      roomgoodslist: [],
      roomgoodsinfodetail: [],
      shoproomgoodsno: [],
      roomgoodsbalancecode: [],
      admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
    };
  },

  methods: {
    getRoomInfo(shopno, roomno, brandno) {
      ax.get_roominfo(shopno, roomno, brandno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          // this.playerno = playerno;
          // this.$refs["modal-info-room"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-room"].show();
          this.roomgoodslist = data.roomgoodslist;

          for (let i = 0; i < data.roomgoodslist.length; i++) {
            this.shoproomgoodsno[i] = data.roomgoodslist[i].shoproomgoodsno;
            for (
              let j = 0;
              j < data.roomgoodslist[i].roomgoodsinfodetail.length;
              j++
            ) {
              this.roomgoodsinfodetail[j] =
                data.roomgoodslist[i].roomgoodsinfodetail[j];
              this.roomgoodsbalancecode[i] =
                data.roomgoodslist[i].roomgoodsinfodetail[
                  j
                ].roomgoodsbalancecode;
              // console.log(i, this.roomgoodsbalancecode[i]);
            }
          }

          // this.getShopRoomlist(shopno);
        } else {
          alert(data);
        }
      });
    },
    // getRoomInfonew(shopno, brandno) {
    //   ax.get_roominfonew(shopno, brandno, (flag, data) => {
    //     console.log(flag, data);
    //     if (flag) {
    //       // this.playerno = playerno;
    //       this.$refs["modal-info-room"].modalOuterStyle.zIndex = 9999;
    //       this.$refs["modal-info-room"].show();
    //       this.roomgoodslist = data.roomgoodslist;

    //       for (let i = 0; i < data.roomgoodslist.length; i++) {
    //         for (
    //           let j = 0;
    //           j < data.roomgoodslist[i].roomgoodsinfodetail.length;
    //           j++
    //         ) {
    //           this.roomgoodsinfodetail[j] =
    //             data.roomgoodslist[i].roomgoodsinfodetail[j];
    //           // console.log(j, this.roomgoodsinfodetail[j]);
    //         }
    //       }

    //       // this.getShopRoomlist(shopno);
    //     } else {
    //       alert(data);
    //     }
    //   });
    // },
    toggle_room_tr(index) {
      if (this.view_hide_room === index) this.view_hide_room = null;
      else this.view_hide_room = index;
    },
    toggle_club_tr(index) {
      if (this.view_hide_room_club === index) this.view_hide_room_club = null;
      else this.view_hide_room_club = index;
    },
    goods_imgview(imagename) {
      if (imagename !== null && imagename !== "") {
        //   return this.adlist[index].path + "/" + this.adlist[index].adimagepath;
        return process.env.VUE_APP_S3_BUCKET_URL + "/" + imagename;
      } else {
        return "/img/no-img.jpg";
      }
    },
    setRoomGoodsEdit(num1) {
      console.log(num1);
      if (!this.shoproomgoodsno[num1]) {
        alert(this.$i18n.t("alert.common.no_goods_no"));
        return false;
      }

      if (!this.roomgoodsbalancecode[num1]) {
        alert(this.$i18n.t("alert.common.enter_strength"));
        return false;
      }

      ax.set_roomgoodsedit(
        this.shoproomgoodsno[num1],
        this.roomgoodsbalancecode[num1],
        (flag, response) => {
          console.log(flag, response);
          if (flag) {
            alert(response.message);
            this.getRoomInfo(this.shopno, this.roomno);
            // this.$bvModal.hide("modal-infor-room");
            // this.sendData.shopmemo = "";
          } else {
            /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
            alert(response.message);
          }
        }
      );
    },
    setRoomGoodsDrop(num) {
      console.log(num);
      if (!this.shoproomgoodsno[num]) {
        alert(this.$i18n.t("alert.common.no_goods_no"));
        return false;
      }

      if (confirm(this.$i18n.t("alert.common.want_to_delete"))) {
        ax.set_roomgoodsdrop(this.shoproomgoodsno[num], (flag, response) => {
          console.log(flag, response);
          if (flag) {
            alert(response.message);
            this.getRoomInfo(this.shopno, this.roomno);
            // this.$bvModal.hide("modal-infor-room");
            // this.sendData.shopmemo = "";
          } else {
            /*this.$notify({
                group: 'alert',
                type: 'info',
                title: '정보',
                text: '발송에 실패하였습니다.'
              })*/
            alert(response.message);
          }
        });
      }
    },
  },
  mixins: [myMixin],
};
</script>

<style scoped></style>
